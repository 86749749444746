import React from "react";
import { MdDone } from "@react-icons/all-files/md/MdDone";
import { IngredientUnit } from "../../../utils/units";
import { FirebaseIngredient, RawItem } from "../types";

type Props = {
  ingredients: FirebaseIngredient[];
  shoppingList: RawItem[];
  ingredientUnit: IngredientUnit;
  servings: number;
};

export function RecipeIngredientsList({ ingredients, shoppingList, ingredientUnit, servings }: Props) {
  return (
    <ul className="list-none p-0 m-0 divide-y divide-lighter-grey">
      {shoppingList.map((item, idx) => {
        const isComplete =
          ingredients.find((ingredient) => ingredient.id === item.ingredient.id)?.completed ?? false;

        const { ingredient, values } = item;
        const maybeServing = values[ingredientUnit].find((x) => x.servingSize === servings);
        const value = maybeServing?.value ?? "Unknown";
        const unitValue = maybeServing?.unit ?? "";
        const hasDualValue = maybeServing?.dualValue?.value !== "" ?? false;
        const dualValueUnit = maybeServing?.dualValue?.unit ?? "";
        const dualValueValue = maybeServing?.dualValue?.value ?? "";

        const title = ingredient.titles.shoppingList;

        return (
          <li key={idx} className="flex items-center py-3" data-recipe-id={item.ingredient.id}>
            <h3 className={`m-0 font-normal text-dark-grey text-xl truncate ${!value && "flex-grow"}`}>
              {title}
            </h3>
            {!!value && (
              <span className="shrink-0 flex-grow border-l-2 border-lighter-grey mx-4 pl-4 text-grey">
                {value} {unitValue} {hasDualValue && `(${dualValueValue} ${dualValueUnit})`}
              </span>
            )}
            <div className="flex items-center ml-4 mr-3 text-green">
              {isComplete && <MdDone className="w-6 h-6" />}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
