import { t } from "@lingui/macro";

export type Translations = ReturnType<typeof useTranslations>;

export function useTranslations() {
  return {
    shoppingList: t({
      id: "ShoppingListPage.title",
      message: "Shopping list",
      comment: "The HTML page title.",
    }),
    featureNotAvailable: t({
      id: "ShoppingListPage.featureNotAvailable",
      message: "Unfortunately this feature is not available.",
      comment: "Displayed when the feature has been disabled.",
    }),
    memberExclusiveBenefit: t({
      id: "ShoppingListPage.memberExclusiveBenefit",
      message: "Get your shopping list in the app with DD+",
      comment: "Displayed when the user is not premium.",
    }),
    ddPlusMembership: t({
      id: "ShoppingListPage.ddPlusMembership",
      message: "DD+ Membership",
      comment: "Displayed when the user is not premium.",
    }),
    signUpFor30DayTrial: t({
      id: "ShoppingListPage.signUpFor30DayTrial",
      message:
        "Sign up for Diet Doctor Plus today to unlock all the premium DD+ features — including your personalized shopping list, recipes, and meal plans in the app, weight loss programs, and more.",
      comment: "Displayed when the user is not logged in.",
    }),
    freeTrial: t({
      id: "ShoppingListPage.freeTrial",
      message: "Start my free trial",
      comment: "Displayed when the user is not logged in.",
    }),
    noIngredientsListed: {
      title: t({
        id: "ShoppingListPage.noIngredientsListed",
        message: "No ingredients listed!",
        comment: "Title. Displayed when the shopping list is empty.",
      }),
      tip: t({
        id: "ShoppingListPage.tip",
        message: "Tip: ",
        comment: "Tip title. Displayed when the shopping list is empty.",
      }),
      toStartAddToShoppingList: t({
        id: "ShoppingListPage.toStartAddToShoppingList",
        message:
          'To start your grocery list, first visit one of our recipes and click "Add to shopping list" beneath the ingredient list. Your ingredients will then appear here.',
        comment: "Tip text. Displayed when the shopping list is empty.",
      }),
    },
    servings: t({
      id: "ShoppingListPage.servings",
      message: "Servings",
      comment:
        'Displayed in the recipe view, together with buttons to increase and decrease. i.e: "Servings (-) 3 (+)"',
    }),
    extras: t({
      id: "ShoppingListPage.extras",
      message: "Extras",
      comment: "Section name for custom ingredients",
    }),
    addItem: t({
      id: "ShoppingListPage.addItem",
      message: "+ Add item",
      comment: "Displayed in the input text to add custom ingredients",
    }),
    deleteThisList: t({
      id: "ShoppingListPage.deleteThisList",
      message: "Would you like to delete this list permanently?",
      comment: 'Displayed after clicking in "Delete" shopping list, in a confirmation pop up.',
    }),
    deleteThisRecipe: t({
      id: "ShoppingListPage.deleteThisRecipe",
      message: "Would you like to delete this recipe permanently?",
      comment: 'Displayed after clicking in "Delete" recipe, in a confirmation pop up.',
    }),
    yes: t({
      id: "ShoppingListPage.yes",
      message: "Yes",
      comment: "Used in the confirmation pop up.",
    }),
    no: t({
      id: "ShoppingListPage.no",
      message: "No",
      comment: "Used in the confirmation pop up.",
    }),
    actions: t({
      id: "ShoppingListPage.actions",
      message: "Actions",
      comment: "Displayed in the shopping list menu.",
    }),
    share: t({
      id: "ShoppingListPage.share",
      message: "Share",
      comment: "Displayed in the shopping list menu.",
    }),
    print: t({
      id: "ShoppingListPage.print",
      message: "Print",
      comment: "Displayed in the shopping list menu.",
    }),
    delete: t({
      id: "ShoppingListPage.delete",
      message: "Delete",
      comment: "Displayed in the shopping list menu.",
    }),
    cancel: t({
      id: "ShoppingListPage.cancel",
      message: "Cancel",
      comment: "Displayed in the shopping list menu.",
    }),
    list: t({
      id: "ShoppingListPage.list",
      message: "List",
      comment: "Displayed in a button that toggles between List/Recipes view.",
    }),
    recipes: t({
      id: "ShoppingListPage.recipes",
      message: "Recipes",
      comment: "Displayed in a button that toggles between List/Recipes view.",
    }),
    completed: t({
      id: "ShoppingListPage.completed",
      message: "Completed",
      comment: "Displayed in a button that toggles between List/Recipes view.",
    }),
  };
}
