import { assign, DoneInvokeEvent, send } from "xstate";
import {
  AddExtraItemEvent,
  Context,
  FirebaseCustomIngredient,
  FirebaseIngredient,
  FirebaseSnapshotEvent,
  GroupBy,
  ListDeleteCustomIngredientEvent,
  ListSetCustomIngredientAsCompleteEvent,
  ListSetCustomIngredientAsIncompleteEvent,
  ListSetIngredientAsCompleteEvent,
  ListSetIngredientAsIncompleteEvent,
  Preferences,
  RawRecipe,
  RecipeDeleteRecipeEvent,
  RecipeIncreaseServingEvent,
  RecipesLoadedEvent,
  ShoppingListEvent,
  ShoppingListMergedIngredient,
  UserStateChangeEvent,
} from "./types";
import { emptyShoppingList } from "./utils";

export const onLocalStorageSnapshot = assign<Context, ShoppingListEvent>({
  data: (_, event) => (event as FirebaseSnapshotEvent).data,
});

export const onFirebaseSnapshot = assign<Context, ShoppingListEvent>({
  data: (_, event) => (event as FirebaseSnapshotEvent).data,
});

export const setCustomIngredientAsComplete = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    ctx.data.list.customIngredients.map((ingredient) => {
      if (ingredient === (event as ListSetCustomIngredientAsCompleteEvent).ingredient) {
        ingredient.completed = true;
      }
      return ingredient;
    });
    return ctx.data;
  },
});

export const setCustomIngredientAsIncomplete = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    ctx.data.list.customIngredients.map((ingredient) => {
      if (ingredient === (event as ListSetCustomIngredientAsIncompleteEvent).ingredient) {
        ingredient.completed = false;
      }
      return ingredient;
    });
    return ctx.data;
  },
});

export const deleteCustomIngredient = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    ctx.data.list.customIngredients = ctx.data.list.customIngredients.filter(
      (ingredient) => ingredient !== (event as ListDeleteCustomIngredientEvent).ingredient
    );
    return ctx.data;
  },
});

export const setIngredientAsIncomplete = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    ctx.data.list.ingredients.map((ingredient) => {
      if (ingredient.id === (event as ListSetIngredientAsIncompleteEvent).id) {
        ingredient.completed = false;
      }
      return ingredient;
    });
    return ctx.data;
  },
});

export const setIngredientAsComplete = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    const ingredientId = (event as ListSetIngredientAsCompleteEvent).id;
    const toUpdate = ctx.data.list.ingredients.find((ingredient) => ingredient.id === ingredientId);
    if (toUpdate !== undefined) {
      ctx.data.list.ingredients.map((ingredient) => {
        if (ingredient.id === ingredientId) {
          ingredient.completed = true;
        }
        return ingredient;
      });
    } else {
      ctx.data.list.ingredients = [...ctx.data.list.ingredients, { id: ingredientId, completed: true }];
    }
    return ctx.data;
  },
});

export const decreaseServings = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    ctx.data.list.items = ctx.data.list.items.map((item) => {
      // decrease servings is tricky because it doesn't decrease -1
      // but could decrease from 36 to 24 or anything. They are not sorted,
      // so we need to find out carefully whats the prev number
      const e = event as RecipeIncreaseServingEvent;
      if (item.uuid === e.uuid) {
        const recipe = e.recipe;
        const all = recipe!.shoppingList[0]?.values[ctx.ingredientUnit]
          .map((x) => x.servingSize)
          .sort((a, b) => a - b)
          .reverse() ?? [e.servings];

        const min = all[all.length - 1];
        const next = all.find((x) => x < e.servings) ?? min;

        const selected = Math.max(min, next);
        item.servings = selected;
      }

      return item;
    });
    return ctx.data;
  },
});

export const increaseServings = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    ctx.data.list.items = ctx.data.list.items.map((item) => {
      // increase servings is tricky because
      // it doesn't increase +1 but could increase from 12 to 24 or anything
      // plus they are not sorted, so we need to find out carefully whats the
      // next number
      const e = event as RecipeIncreaseServingEvent;
      if (item.uuid === e.uuid) {
        const recipe = e.recipe;
        const all = recipe.shoppingList[0]?.values[ctx.ingredientUnit]
          .map((x) => x.servingSize)
          .sort((a, b) => a - b) ?? [e.servings];

        const max = all[all.length - 1];
        const next = all.find((x) => x > e.servings) ?? max;

        const selected = Math.min(max, next);
        item.servings = selected;
      }

      return item;
    });
    return ctx.data;
  },
});

export const setRelatedItemsAsNotCompleted = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    // When we change servings, all merged ingredients need
    // to be marked as not complete
    const recipeShoppingList = (event as RecipeIncreaseServingEvent).recipe.shoppingList;
    ctx.data.list.ingredients = ctx.data.list.ingredients.map((ingredients) => {
      const hasIngredient = recipeShoppingList.find((i) => i.ingredient.id === ingredients.id) !== undefined;
      if (hasIngredient) {
        ingredients.completed = false;
      }

      return ingredients;
    });
    return ctx.data;
  },
});

export const deleteRecipe = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    // we could end up with multiples instances of the same
    // recipe, that's why we use an internal unique id
    ctx.data.list.items = ctx.data.list.items.filter(
      (item) => item.uuid !== (event as RecipeDeleteRecipeEvent).uuid
    );
    return ctx.data;
  },
});

export const storeRecipes = assign<Context, ShoppingListEvent>({
  recipes: (_, event) => (event as RecipesLoadedEvent).data.filter((x) => x !== null) as RawRecipe[],
});

export const syncIngredients = assign<Context, ShoppingListEvent>({
  data: (ctx) => {
    // the list of ingredients in Firebase need to be equal to the
    // ingredients that come from the merged shopping list
    ctx.data.list.ingredients =
      ctx.mergedShoppingList?.map((mIngredient) => {
        const p = ctx.data.list.ingredients.find((i) => i.id === mIngredient.ingredient.id);
        const fi: FirebaseIngredient = {
          id: mIngredient.ingredient.id,
          completed: p?.completed ?? false,
        };
        return fi;
      }) ?? [];
    return ctx.data;
  },
});

export const persistMergedShoppingList = assign<Context, ShoppingListEvent>({
  mergedShoppingList: (_, event) => {
    const e = event as DoneInvokeEvent<ShoppingListMergedIngredient[]>;
    return e.data;
  },
});

export const addExtraItem = assign<Context, ShoppingListEvent>({
  data: (ctx, event) => {
    const item: FirebaseCustomIngredient = {
      completed: false,
      title: (event as AddExtraItemEvent).title,
    };

    ctx.data.list.customIngredients = [item, ...ctx.data.list.customIngredients];

    return ctx.data;
  },
});

export const setGroupByRecipes = assign<Context, ShoppingListEvent>({
  groupBy: () => GroupBy.RECIPES,
});

export const setGroupByDepartments = assign<Context, ShoppingListEvent>({
  groupBy: () => GroupBy.DEPARTMENTS,
});

export const share = (ctx: Context) => {
  const text = ctx.mergedShoppingList?.reduce((acc, ingredient) => {
    /**
    # Extras
    [ ] Extra item 1

    # Keto mummy dogs
    [ ] Almond flour
    [ ] ...
   */
    return `${acc}\n# ${ingredient.ingredient.titles.singular}\n`;
  }, "");

  const data = {
    title: "My shopping list",
    text,
  };

  if (navigator.canShare && navigator.canShare(data)) {
    navigator.share &&
      navigator
        .share(data)
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
  } else {
    console.log("This browser does not support share functionality.");
  }
};

export const print = () => {
  window.print();
};

export const setPreferences = (setStoredPreferences: (value: Preferences) => void) => (ctx: Context) => {
  setStoredPreferences({
    groupBy: ctx.groupBy,
  });
};

export const setUser = assign<Context, ShoppingListEvent>({
  userId: (_, event) => (event as UserStateChangeEvent).userId,
  firebaseUserId: (_, event) => (event as UserStateChangeEvent).firebaseUserId,
});

export const setIsPremium = assign<Context, ShoppingListEvent>({
  isPremium: (_, event) => (event as UserStateChangeEvent).isPremium,
});

export const setIngredientUnit = assign<Context, ShoppingListEvent>({
  ingredientUnit: (ctx, event) => (event as UserStateChangeEvent).ingredientUnit,
});

export const updateNotification = (ctx: Context) => {
  // the notification icon is out of the scope of this page
  const customToBeCompleted = ctx.data.list.customIngredients.filter((x) => !x.completed).length;
  const ingredientsToBeCompleted = ctx.data.list.ingredients.filter((x) => !x.completed).length;
  const totalToBeCompleted = customToBeCompleted + ingredientsToBeCompleted;

  // updates the shopping list badge in the navigation
  window.elements?.nav.setShoppingListNumItems(totalToBeCompleted);
};

export const deleteList = assign<Context, ShoppingListEvent>({
  data: () => emptyShoppingList(),
  recipes: () => [],
  mergedShoppingList: () => [],
});

export const saveTmpRecipeDeleteInfo = assign<Context, ShoppingListEvent>({
  tmpDeleteRecipeEventInfo: (_, e) => ({
    recipe: (e as RecipeDeleteRecipeEvent).recipe,
    uuid: (e as RecipeDeleteRecipeEvent).uuid,
  }),
});

export const sendDeleteRecipeEvent = send<Context, ShoppingListEvent>((ctx) => ({
  type: "DELETE",
  recipe: ctx.tmpDeleteRecipeEventInfo?.recipe,
  uuid: ctx.tmpDeleteRecipeEventInfo?.uuid,
}));

export const clearTmpDeleteRecipeEventInfo = assign<Context, ShoppingListEvent>({
  tmpDeleteRecipeEventInfo: () => undefined,
});

export const refreshRecipes = send(
  (ctx: Context, event: ShoppingListEvent) => ({
    type: "RECIPE_REFRESH_INTENT",
    data: (event as FirebaseSnapshotEvent).data,
    recipes: ctx.recipes,
  }),
  { to: "recipes" }
);

export const writeToFirebase = send(
  (ctx: Context, _: ShoppingListEvent) => ({ type: "WRITE_TO_FIREBASE", data: ctx.data }), // eslint-disable-line @typescript-eslint/no-unused-vars
  {
    to: "firebase",
  }
);

export const writeToLocalStorage = (ctx: Context) => {
  localStorage.setItem("dd/shopping-list", JSON.stringify(ctx.data));
};
