import { MdAddCircle } from "@react-icons/all-files/md/MdAddCircle";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { MdExpandLess } from "@react-icons/all-files/md/MdExpandLess";
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import { MdRemoveCircle } from "@react-icons/all-files/md/MdRemoveCircle";
import React, { useCallback, useState } from "react";
import { InterpreterFrom, StateMachine, StateSchema } from "xstate";
import { getSrc } from "../../../utils/image";
import { IngredientUnit } from "../../../utils/units";
import { Translations } from "../translations";
import { Context, FirebaseIngredient, RawRecipe, RecipeShoppingList, ShoppingListEvent } from "../types";
import { RecipeIngredientsList } from "./RecipeIngredientsList";

const everyIngredientCompleted = (s: RecipeShoppingList, i: FirebaseIngredient[]) =>
  s.every((item) => i.find((ingredient) => ingredient.id === item.ingredient.id)?.completed);

type Props = {
  translations: Translations;
  uuid: string;
  servings: number;
  recipe?: RawRecipe;
  ingredientUnit: IngredientUnit;
  ingredients: FirebaseIngredient[];
  send: InterpreterFrom<StateMachine<Context, StateSchema<Context>, ShoppingListEvent>>["send"];
};

export function Recipe({ translations, uuid, servings, recipe, ingredientUnit, ingredients, send }: Props) {
  const [expanded, setExpanded] = useState(false);

  const imageRef = useCallback((image: any) => {
    if (image !== null) {
      setTimeout(() => {
        (image as HTMLImageElement).classList.remove("opacity-0");
      }, 500);
    }
  }, []);

  if (recipe === undefined) {
    // image placeholder during loading...
    return (
      <li className="border-t border-lighter-grey pt-5 pb-7">
        <div className="mb-2">
          <div
            className="relative bg-cover rounded-lg bg-no-repeat mb-3 bg-gradient-to-r from-lightest-grey to-lighter-grey"
            style={{
              height: "140px",
            }}
          ></div>
        </div>
        <div className="flex">
          <h2 className="flex-grow m-0"></h2>
        </div>
      </li>
    );
  }

  const { id, slug, title, images, shoppingList } = recipe;
  const allRecipesIngredientsCompleted = everyIngredientCompleted(recipe.shoppingList, ingredients);

  const onIncreaseServing = () => send({ type: "RECIPE_INCREASE_SERVING", uuid, recipe, servings });
  const onDecreaseServing = () => send({ type: "RECIPE_DECREASE_SERVING", uuid, recipe, servings });
  const handleDeleteRecipe = () => send({ type: "RECIPE_DELETE_RECIPE", recipe, uuid });
  const toggleIngredients = () => setExpanded(!expanded);

  return (
    <li
      data-recipe-id={id}
      data-recipe-uuid={uuid}
      data-recipe-slug={slug}
      className={`border-t border-lighter-grey pt-5 ${!expanded && "pb-7"}`}
    >
      <div className="mb-2">
        <div
          className="relative overflow-hidden bg-cover rounded-lg bg-no-repeat mb-3 bg-gradient-to-r from-lightest-grey to-lighter-grey"
          style={{ height: "125px" }}
        >
          <span
            className="z-10 absolute bg-white bg-opacity-90 top-2 left-2 rounded-full text-white font-medium flex items-center justify-center"
            style={{
              height: "30px",
              width: "30px",
            }}
            onClick={handleDeleteRecipe}
          >
            <MdClose size={26} className="text-grey" />
          </span>
          <img
            className="opacity-0 transition duration-700 w-full"
            ref={imageRef}
            src={getSrc(images.hz, { width: 748, height: 280 })}
            alt={title}
          />
          {allRecipesIngredientsCompleted && (
            <span
              className="z-10 absolute bg-green top-2 right-2 rounded-full text-white font-medium"
              style={{
                lineHeight: 0,
                height: "30px",
                paddingTop: "4px",
                width: "30px",
              }}
            >
              <svg
                className="absolute top-2.5 left-2"
                width="15"
                height="12"
                viewBox="0 0 15 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.348 2.91538L6.21119 11.1308C5.93692 11.4077 5.57122 11.5 5.20552 11.5C4.83982 11.5 4.47413 11.4077 4.19985 11.1308L1 7.9C0.451454 7.34616 0.451454 6.51538 1 5.96154C1.54855 5.40769 2.37137 5.40769 2.91991 5.96154L5.20552 8.26923L8.81678 4.62308L12.428 0.976925C12.9766 0.423079 13.7994 0.423079 14.348 0.976925C14.8965 1.43846 14.8965 2.36154 14.348 2.91538Z"
                  fill="#ffffff"
                />
              </svg>
            </span>
          )}
        </div>
        <div className="flex">
          <h2 className="flex-grow m-0">
            <a className="text-black font-medium tracking-tight text-2xl hover:opacity-50" href={`${slug}`}>
              {title}
            </a>
          </h2>

          <MdExpandLess
            className={`shrink-0 h-8 w-10 ml-2 mr-1 print:hidden ${expanded ? "" : "hidden"}`}
            onClick={toggleIngredients}
          />
          <MdExpandMore
            className={`shrink-0 h-8 w-10 ml-2 mr-1 print:hidden ${expanded ? "hidden" : ""}`}
            onClick={toggleIngredients}
          />
        </div>
      </div>
      <div className={`${expanded ? "" : "hidden print:block"}`}>
        <div className="flex items-center">
          <div className="flex items-center">
            <span className="grow-0 shrink-0 mr-4 text-sm font-extrabold text-grey uppercase">
              {translations.servings}
            </span>
            <button
              className="grow-0 shrink-0 print:hidden w-12 h-6 p-0 m-0 hover:opacity-50 transition"
              onClick={onDecreaseServing}
            >
              <MdRemoveCircle className="text-light-grey w-6 h-6" />
            </button>
            <span className="w-5 flex justify-center tabular-nums grow-0 shrink-0 text-sm font-extrabold text-grey uppercase">
              {servings}
            </span>
            <button
              className="grow-0 shrink-0 print:hidden w-12 h-6 p-0 m-0 hover:opacity-50 transition"
              onClick={onIncreaseServing}
            >
              <MdAddCircle className="text-light-grey w-6 h-6" />
            </button>
          </div>
        </div>
        <RecipeIngredientsList
          ingredients={ingredients}
          shoppingList={shoppingList}
          ingredientUnit={ingredientUnit}
          servings={servings}
        />
      </div>
    </li>
  );
}
