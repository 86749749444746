import { ApolloClient, NormalizedCacheObject, useApolloClient } from "@apollo/client";
import { useMachine } from "@xstate/react";
import { getFirestore } from "firebase/firestore";
import React, { useContext, useEffect, useMemo } from "react";
import { useMeasurementUnit } from "../../hooks/useMeasurementUnit/useMeasurementUnit";
import { useStateWithLocalStorage as useLocalStorage } from "../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import firebaseApp from "../../utils/firebase";
import { isBrowser } from "../../utils/ssr";
import Loading from "../../components/Loading/Loading";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { pageContext } from "../../components/PageProvider/PageProvider";
import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";
import { List } from "./list/List";
import { machine } from "./machine";
import { ConfirmationModal } from "./others/ConfirmationModal";
import { RecipeList } from "./recipe/RecipeList";
import { ShoppingListMembershipBanner } from "./ShoppingListMembershipBanner";
import { Toolbar } from "./toolbar/Toolbar";
import { useTranslations } from "./translations";
import { GroupBy, LS_RECIPES_SHOPPING_LIST_PREFERENCES_KEY, Preferences } from "./types";

const ShoppingListPage = () => {
  if (!isBrowser()) {
    return null;
  }

  const { loading: isUserLoading, user, firebaseUserId, premium: isPremium } = useContext(LoginContext);
  const tt = useTranslations();
  const { locale: language } = useContext(pageContext);

  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [preferences, setPreferences] = useLocalStorage<Preferences>(LS_RECIPES_SHOPPING_LIST_PREFERENCES_KEY, {
    groupBy: GroupBy.RECIPES,
  });
  const { ingredientUnit, loading: userUnitLoading } = useMeasurementUnit();

  const m = useMemo(
    () =>
      machine({
        client,
        language,
        db: getFirestore(firebaseApp),
        storedPreferences: preferences,
        setStoredPreferences: setPreferences,
      }),
    [client, language, preferences, setPreferences]
  );
  const [state, send, service] = useMachine(m);

  useEffect(() => {
    if (!isUserLoading && !userUnitLoading) {
      send({
        type: "USER_STATE_CHANGE",
        userId: user?.userId,
        firebaseUserId,
        isUserLoading,
        isPremium,
        ingredientUnit,
      });
    }
  }, [isUserLoading, isPremium, send, user?.userId, firebaseUserId, userUnitLoading, ingredientUnit]);

  const { context: ctx } = state;
  const { ingredientUnit: ctxIngredientUnit, groupBy } = ctx;

  // is readyWithPremium state, but the first time we wait for
  // firebase to load the shopping list
  const readyButBusy = state.matches({ readyWithPremium: "loading" });
  const loadingState =
    state.matches("waitingForUserState") ||
    state.matches("warmingUp") ||
    state.matches("loading") ||
    state.matches("legacy") ||
    readyButBusy;

  const isFeatureDisabledState = state.matches("disabled");
  const onlyForPremiumState = state.matches("onlyForPremium");
  const readyState =
    (state.matches("readyWithPremium") && !readyButBusy) || state.matches("onlyForPremium.ready");

  const handleDeleteShoppingListYes = () => send("DELETE_SHOPPING_LIST_YES");
  const handleDeleteShoppingListNo = () => send("DELETE_SHOPPING_LIST_NO");
  const handleDeleteRecipeYes = () => send("DELETE_RECIPE_YES");
  const handleDeleteRecipeNo = () => send("DELETE_RECIPE_NO");

  return (
    <>
      <SEOReactHelmet title={`Diet Doctor - ${tt.shoppingList}`} />
      {state.matches({ readyWithPremium: "deleteList" }) && (
        <ConfirmationModal
          title={tt.deleteThisList}
          yesLabel={tt.yes}
          noLabel={tt.no}
          onConfirm={handleDeleteShoppingListYes}
          onCancel={handleDeleteShoppingListNo}
        />
      )}
      {state.matches({ readyWithPremium: "deleteRecipe" }) && (
        <ConfirmationModal
          title={tt.deleteThisRecipe}
          yesLabel={tt.yes}
          noLabel={tt.no}
          onConfirm={handleDeleteRecipeYes}
          onCancel={handleDeleteRecipeNo}
        />
      )}
      <div className="px-9 pt-7 pb-12 max-w-4xl mx-auto">
        {loadingState && <Loading />}

        {isFeatureDisabledState && (
          <>
            <h3>Diet Doctor</h3>
            <p>{tt.featureNotAvailable}</p>
          </>
        )}

        {readyState && (
          <>
            <section>
              <Toolbar translations={tt} service={service} />
            </section>
            {onlyForPremiumState && <ShoppingListMembershipBanner />}
            <section className="flex relative">
              {state.context.mergedShoppingList && groupBy === GroupBy.DEPARTMENTS && (
                <>
                  <List
                    translations={tt}
                    ingredientUnit={ctxIngredientUnit}
                    data={state.context.data}
                    mergedShoppingList={state.context.mergedShoppingList}
                    send={send}
                  />
                  {onlyForPremiumState && state.context.mergedShoppingList.length > 0 && (
                    <div
                      className="w-full h-full absolute bottom-0"
                      style={{
                        background:
                          "linear-gradient(0deg, rgb(255 255 255) 0%, rgb(255, 255, 255) 35%, rgb(255 255 255 / 0%) 100%)",
                      }}
                    ></div>
                  )}
                </>
              )}
              {state.context.recipes && groupBy === GroupBy.RECIPES && (
                <RecipeList
                  translations={tt}
                  send={send}
                  ingredientUnit={ctxIngredientUnit}
                  recipes={state.context.recipes}
                  ingredients={state.context.data.list.ingredients}
                  items={state.context.data.list.items}
                />
              )}
            </section>
          </>
        )}
      </div>
    </>
  );
};

// ts-prune-ignore-next
export default ShoppingListPage;
