import styled from "@emotion/styled";
import { BiTrash } from "@react-icons/all-files/bi/BiTrash";
import { MdHighlightOff } from "@react-icons/all-files/md/MdHighlightOff";
import { MdMoreHoriz } from "@react-icons/all-files/md/MdMoreHoriz";
import { MdPrint } from "@react-icons/all-files/md/MdPrint";
import { MdShare } from "@react-icons/all-files/md/MdShare";
import { useActor, useMachine } from "@xstate/react";
import anime from "animejs";
import * as React from "react";
import { useMemo, useRef, useState } from "react";
import { createMachine, InterpreterFrom, StateMachine, StateSchema } from "xstate";
import { Translations } from "../translations";
import { Context, GroupBy, ShoppingListEvent } from "../types";
import { hasIngredients } from "../utils";
import { Switch } from "./Switch";

type InputFocusEvent = React.FocusEvent<HTMLInputElement>;
type InputKeyboardEvent = React.KeyboardEvent<HTMLInputElement>;
type InputChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

type Props = {
  translations: Translations;
  service: InterpreterFrom<StateMachine<Context, StateSchema<Context>, ShoppingListEvent>>;
};

type ToolbarFocusInputEvent = {
  type: "FOCUS_INPUT";
  inputRef: HTMLInputElement;
  switchRef: HTMLDivElement;
};
type ToolbarBlurInputEvent = {
  type: "BLUR_INPUT";
  inputRef: HTMLInputElement;
  switchRef: HTMLDivElement;
};
type ToolbarOpenToolsEvent = { type: "OPEN_TOOLS"; toolsRef: HTMLDivElement };
type ToolbarExitToolsEvent = { type: "EXIT_TOOLS"; toolsRef: HTMLDivElement };
type ToolbarEvent =
  | ToolbarFocusInputEvent
  | ToolbarOpenToolsEvent
  | ToolbarExitToolsEvent
  | ToolbarBlurInputEvent;

const toolbarMachine = createMachine<{}, ToolbarEvent>(
  {
    predictableActionArguments: true,
    initial: "idle",
    states: {
      idle: {
        on: {
          FOCUS_INPUT: "focusedIn",
          OPEN_TOOLS: "tools",
        },
      },
      tools: {
        entry: ["showTools"],
        exit: ["hideTools"],
        on: {
          EXIT_TOOLS: "idle",
        },
      },
      focusedIn: {
        invoke: {
          onDone: "focused",
          src: "textInputFocusIn",
        },
      },
      focused: {
        on: {
          BLUR_INPUT: "focusedOut",
        },
      },
      focusedOut: {
        invoke: {
          onDone: "idle",
          src: "textInputFocusOut",
        },
      },
    },
  },
  {
    actions: {
      showTools: (_, event) => {
        const e = event as ToolbarOpenToolsEvent;
        e.toolsRef.classList.remove("opacity-0");
        e.toolsRef.classList.add("opacity-100");
        e.toolsRef.classList.remove("pointer-events-none");
      },
      hideTools: (_, event) => {
        const e = event as ToolbarExitToolsEvent;
        e.toolsRef.classList.remove("opacity-100");
        e.toolsRef.classList.add("opacity-0");
        e.toolsRef.classList.add("pointer-events-none");
      },
    },
    services: {
      textInputFocusIn: (_, event) => {
        const e = event as ToolbarFocusInputEvent;
        return anime
          .timeline()
          .add({
            targets: e.switchRef,
            opacity: "0",
            duration: 400,
            easing: "easeInQuad",
          })
          .add(
            {
              targets: e.inputRef,
              width: "100%",
              duration: 400,
              easing: "easeOutQuad",
            },
            "-=300"
          ).finished;
      },
      textInputFocusOut: (_, event) => {
        const e = event as ToolbarBlurInputEvent;
        return anime
          .timeline()
          .add({
            targets: e.inputRef,
            width: "0",
            duration: 400,
            easing: "easeOutQuad",
          })
          .add(
            {
              targets: e.switchRef,
              opacity: "1",
              duration: 400,
              easing: "easeOutQuad",
            },
            "-=300"
          ).finished;
      },
    },
  }
);

export function Toolbar({ service, translations }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const switchRef = useRef<HTMLDivElement>(null);
  const toolsRef = useRef<HTMLDivElement>(null);
  const [stateParent, sendParent] = useActor(service);
  const onlyForPremiumState = stateParent.matches("onlyForPremium");
  const m = useMemo(() => toolbarMachine, []);
  const [state, send] = useMachine(m);
  const [extraTitle, setExtraTitle] = useState("");

  const onAddItemTitleChange = (e: InputChangeEvent) => setExtraTitle(e.target.value);

  const onAddItemChange = (e: InputKeyboardEvent) => {
    if (e.key === "Enter" && extraTitle.trim() !== "") {
      sendParent({ type: "ADD_EXTRA_ITEM", title: extraTitle });
      setExtraTitle("");
      inputRef.current?.blur();
    }
  };

  const onClickOnAdd = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (extraTitle.trim() !== "") {
      sendParent({ type: "ADD_EXTRA_ITEM", title: extraTitle });
      setExtraTitle("");
      inputRef.current?.blur();
    }
  };

  const onCancel = () => send({ type: "EXIT_TOOLS", toolsRef: toolsRef.current! });
  const onOpenTools = () => send({ type: "OPEN_TOOLS", toolsRef: toolsRef.current! });
  const onGroupByDepartments = () => sendParent({ type: "SET_GROUPED_BY_DEPARTMENTS" });
  const onGroupByRecipes = () => sendParent({ type: "SET_GROUPED_BY_RECIPES" });

  const onPrint = () => {
    onCancel();
    sendParent({ type: "PRINT" });
  };

  const onDelete = () => {
    onCancel();
    sendParent({ type: "DELETE_SHOPPING_LIST" });
  };

  const onShare = () => {
    onCancel();
    sendParent({ type: "SHARE" });
  };

  const isGroupByDepartment = stateParent.context.groupBy === GroupBy.DEPARTMENTS;

  const onInputFocus = (e: InputFocusEvent) => {
    e.target.placeholder = "";
    sendParent({ type: "SET_GROUPED_BY_DEPARTMENTS" });
    send({ type: "FOCUS_INPUT", inputRef: inputRef.current!, switchRef: switchRef.current! });
  };

  const onInputBlur = (e: InputFocusEvent) => {
    send({ type: "BLUR_INPUT", inputRef: inputRef.current!, switchRef: switchRef.current! });
    setExtraTitle("");
    e.target.placeholder = translations.addItem;
  };

  const canShare = navigator.share !== undefined;

  const shouldToolbarDisplay =
    hasIngredients(stateParent.context.mergedShoppingList, stateParent.context.data.list.customIngredients) &&
    !onlyForPremiumState;

  return (
    <>
      <div className="flex flex-col">
        <div className="relative flex w-full">
          <h1 className="flex-grow m-0 p-0 font-medium tracking-tighter leading-normal">
            {translations.shoppingList}
          </h1>
          <div
            className={`print:hidden shrink-0 flex items-center transition ${
              shouldToolbarDisplay ? "cursor-pointer hover:opacity-50" : "pointer-events-none opacity-20"
            }`}
            onClick={onOpenTools}
          >
            <MdMoreHoriz className="w-8 h-8" />
          </div>
        </div>
        <div className="flex overflow-hidden w-full print:hidden">
          <div ref={inputRef} className="relative flex w-0 flex-grow shrink-0">
            {state.matches("focused") && (
              <button
                className="top-2 absolute right-6 font-medium z-10 text-grey text-base cursor-pointer"
                onMouseDown={onClickOnAdd}
              >
                Add
              </button>
            )}
            <CustomInput
              className={`box-border bg-lighter-grey rounded-full pl-6 pr-6 mr-3 text-base font-medium ${
                state.matches("focused") ? "pr-16" : ""
              }`}
              data-testid="input-add-item"
              data-state={state.value}
              value={extraTitle}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              onChange={onAddItemTitleChange}
              onKeyDown={onAddItemChange}
              placeholder={translations.addItem}
              type="text"
            />
          </div>
          <Switch ref={switchRef} enabled={!onlyForPremiumState}>
            <span data-value={isGroupByDepartment ? 0 : 1}></span>
            <button data-active={isGroupByDepartment} onClick={onGroupByDepartments}>
              {translations.list}
            </button>
            <button data-active={!isGroupByDepartment} onClick={onGroupByRecipes}>
              {translations.recipes}
            </button>
          </Switch>
        </div>
      </div>
      <div
        ref={toolsRef}
        className="fixed z-9999 bottom-0 left-0 right-0 top-0 bg-lighter-grey bg-opacity-95 flex flex-col justify-end md:justify-start md:pt-32 p-4 opacity-0 pointer-events-none transition duration-300"
        onClick={onCancel}
      >
        <div className="flex w-full md:max-w-xl md:mx-auto items-center p-2">
          <h3 className="text-xl font-medium m-0 w-full flex-grow-1">{translations.actions}</h3>
          <button className="shrink-0 cursor-pointer hover:opacity-50 transition flex p-2" onClick={onCancel}>
            <MdHighlightOff className="w-6 h-6" />
          </button>
        </div>
        <ul className="rounded-md list-none m-0 p-0 w-full md:max-w-xl md:mx-auto bg-white divide-y divide-light-grey px-6 md:px-12 py-6 flex flex-col">
          {canShare && (
            <li className="flex items-center cursor-pointer hover:opacity-50 transition" onClick={onShare}>
              <button className="flex-grow-1 text-black w-full flex justify-start bg-white py-4">
                {translations.share}
              </button>
              <MdShare className="shrink-0 text-grey w-6 h-6" />
            </li>
          )}
          <li className="flex items-center cursor-pointer hover:opacity-50 transition" onClick={onPrint}>
            <button className="flex-grow-1 text-black w-full flex justify-start bg-white py-4">
              {translations.print}
            </button>
            <MdPrint className="shrink-0 text-grey w-6 h-6" />
          </li>
          <li className="flex items-center cursor-pointer hover:opacity-50 transition" onClick={onDelete}>
            <button className="flex-grow-1 text-black w-full flex justify-start bg-white py-4">
              {translations.delete}
            </button>
            <BiTrash className="shrink-0 text-grey w-6 h-6" />
          </li>
        </ul>
      </div>
    </>
  );
}

const CustomInput = styled.input`
  padding-top: 3px;
  flex: 1 0 auto;
  width: 0;
  height: 40px;
  :placeholder-shown {
    min-width: 100px;
  }
`;
