import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import GetUserPreferencesQuery from "../../graphql/GetUserPreferences.graphql";
import UpdateUser from "../../graphql/UpdateUser.graphql";
import { UpdateUser_updateUser } from "../../types/UpdateUser";

export function useUserPreferences() {
  const [preferences, setPreferences] = useState<UpdateUser_updateUser>();
  const [updateUserMutation] = useMutation<{ updateUser: UpdateUser_updateUser }>(UpdateUser);
  const { data, loading } = useQuery<{ getUser: UpdateUser_updateUser }>(GetUserPreferencesQuery, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  });

  useEffect(() => {
    setPreferences(data?.getUser);
  }, [data]);

  const updateUserPreferences = useCallback(
    async (payload: Omit<UpdateUser_updateUser, "email" | "firstName" | "__typename">) => {
      const { data } = await updateUserMutation({
        variables: {
          input: payload,
        },
      });

      setPreferences(data?.updateUser);
    },
    [updateUserMutation]
  );

  return { updateUserPreferences, loading, preferences };
}
