import { FirebaseCustomIngredient, FirebaseShoppingListPayload, ShoppingListMergedIngredient } from "./types";

export const emptyShoppingList = (): FirebaseShoppingListPayload => ({
  version: "1",
  list: {
    ingredients: [],
    customIngredients: [],
    items: [],
  },
});

export const hasIngredients = (
  mergedShoppingList: ShoppingListMergedIngredient[] | undefined,
  customIngredients: FirebaseCustomIngredient[] | undefined
): boolean => {
  return mergedShoppingList && customIngredients
    ? mergedShoppingList.length + customIngredients.length > 0
    : false;
};

export const emptyServingItem = () => ({
  servingSize: 1,
  unit: "",
  value: "",
  dualValue: {
    unit: "",
    value: "",
  },
});
