import { useState } from "react";
import { default as React } from "react";
import { capitalizeFirstLetter } from "../../../utils/string";
import { IngredientUnit } from "../../../utils/units";
import { ShoppingListMergedIngredient } from "../types";

type Props = {
  isCustomIngredient: boolean;
  handleChange: VoidFunction;
  handleHideOne?: VoidFunction;
  isCompleted: boolean;
  title: string;
  ingredientUnit: IngredientUnit;
  servings: number | null;
  values: ShoppingListMergedIngredient["values"] | null;
};

export function ListRow(props: Props) {
  const { isCustomIngredient, handleChange, handleHideOne, isCompleted, ingredientUnit, values, title } = props;

  const val = values ? `${values[ingredientUnit].value} ${values[ingredientUnit].unit}` : null;

  const [completeState, setCompleteState] = useState(isCompleted);

  const handleDelayedComplete = () => {
    setCompleteState(!completeState);
    setTimeout(() => handleChange(), 500);
  };
  const handleDelayedHidden = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleHideOne?.();
  };

  return (
    <li
      className="print:border-0 print:py-1 no-tap-highlight cursor-pointer flex w-full px-1.5 pt-3 pb-2 border-b border-lighter-grey items-start"
      onClick={handleDelayedComplete}
    >
      <CompletedCheckbox isCompleted={completeState} />
      <div className="flex flex-grow items-baseline truncate print:grow-0 print:items-center">
        <h3 className="m-0 p-0 pl-4 print:pl-0 font-normal text-lg print:text-sm truncate">
          {capitalizeFirstLetter(title)}
        </h3>
        {val && (
          <>
            <span className="hidden print:flex print:mr-1 print:text-sm print:grow-0">,</span>
            <span className="self-start pt-0.5 shrink-0 ml-2 pl-2 border-l-2 print:ml-0 print:pl-0 print:border-none text-base text-grey print:text-black print:text-sm border-lighter-grey">
              {val}
            </span>
          </>
        )}
      </div>
      {isCustomIngredient && (
        <span
          className="print:hidden flex grow-0 shrink-0 justify-center items-center w-6 h-6 hover:opacity-50 transition"
          onClick={handleDelayedHidden}
        >
          <CrossIcon />
        </span>
      )}
    </li>
  );
}

function CompletedCheckbox({ isCompleted }: { isCompleted: boolean }) {
  return (
    <span
      className={`print:hidden shrink-0 w-6 h-6 select-none overflow-hidden relative flex transition rounded-full border-2 ${
        isCompleted ? "bg-green border-green print:bg-white print:border-light-grey" : "border-light-grey"
      }`}
      style={{ width: "22px", height: "22px" }}
    >
      <svg
        className={`absolute transition ${isCompleted ? "top-1" : "top-6"}`}
        style={{ transitionProperty: "top", left: "2px" }}
        width="13"
        height="11"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.348 2.91538L6.21119 11.1308C5.93692 11.4077 5.57122 11.5 5.20552 11.5C4.83982 11.5 4.47413 11.4077 4.19985 11.1308L1 7.9C0.451454 7.34616 0.451454 6.51538 1 5.96154C1.54855 5.40769 2.37137 5.40769 2.91991 5.96154L5.20552 8.26923L8.81678 4.62308L12.428 0.976925C12.9766 0.423079 13.7994 0.423079 14.348 0.976925C14.8965 1.43846 14.8965 2.36154 14.348 2.91538Z"
          fill="#ffffff"
        />
      </svg>
    </span>
  );
}

const CrossIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7918 13.134L13.3231 14.6028L7.50016 8.77987L1.67725 14.6028L0.208496 13.134L6.03141 7.31112L0.208496 1.4882L1.67725 0.0194492L7.50016 5.84237L13.3231 0.0194492L14.7918 1.4882L8.96891 7.31112L14.7918 13.134Z"
      fill="#999"
    />
  </svg>
);
