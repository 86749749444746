import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Firestore } from "firebase/firestore";
import { getRemoteConfig, getValue } from "firebase/remote-config";
import { sha256 } from "js-sha256";
import { assign, createMachine, DoneInvokeEvent } from "xstate";
import { Locale } from "../../types/Locale";
import { sendEvent } from "../../utils/gtm";
import { IngredientUnit } from "../../utils/units";
import {
  addExtraItem,
  clearTmpDeleteRecipeEventInfo,
  decreaseServings,
  deleteCustomIngredient,
  deleteList,
  deleteRecipe,
  increaseServings,
  onFirebaseSnapshot,
  onLocalStorageSnapshot,
  persistMergedShoppingList,
  print,
  refreshRecipes,
  saveTmpRecipeDeleteInfo,
  sendDeleteRecipeEvent,
  setCustomIngredientAsComplete,
  setCustomIngredientAsIncomplete,
  setGroupByDepartments,
  setGroupByRecipes,
  setIngredientAsComplete,
  setIngredientAsIncomplete,
  setIsPremium,
  setIngredientUnit,
  setPreferences,
  setRelatedItemsAsNotCompleted,
  setUser,
  share,
  storeRecipes,
  syncIngredients,
  updateNotification,
  writeToFirebase,
  writeToLocalStorage,
} from "./actions";
import {
  firebaseFetchAndActivate,
  loadMergedShoppingList,
  loadOneRecipeById2,
  loadShoppingListFromLocalStorage,
  recipesSubscription,
  subscribeToShoppingList,
} from "./services";
import {
  Context,
  FirebaseShoppingListResult,
  GroupBy,
  Preferences,
  RecipesLoadedEvent,
  ShoppingListEvent,
  UserStateChangeEvent,
  WEB_FEATURE_SHOPPING_LIST,
} from "./types";
import { emptyShoppingList } from "./utils";

type MachineOptions = {
  language: Locale;
  client: ApolloClient<NormalizedCacheObject>;
  db: Firestore;
  storedPreferences: Preferences;
  setStoredPreferences: (value: Preferences) => void;
};

export const machine = (options: MachineOptions) => {
  const setPreferencesInStorage = setPreferences(options.setStoredPreferences);

  return createMachine<Context, ShoppingListEvent>(
    {
      predictableActionArguments: true,
      id: "shopping",
      initial: "waitingForUserState",
      context: {
        language: options.language,
        client: options.client,
        firebaseRemoteConfig: getRemoteConfig(),
        ingredientUnit: IngredientUnit.IMPERIAL,
        groupBy: GroupBy.DEPARTMENTS,
        isPremium: false,
        data: emptyShoppingList(),
      },
      states: {
        waitingForUserState: {
          on: {
            USER_STATE_CHANGE: [
              {
                target: "warmingUp",
                cond: "isUserNotLoading",
                actions: ["setUser", "setIsPremium", "setIngredientUnit"],
              },
            ],
          },
        },
        warmingUp: {
          invoke: {
            src: "firebaseFetchAndActivate",
            onDone: [
              { target: "disabled", cond: "featureIsDisabled" },
              { target: "readyWithPremium", cond: "isPremiumAndFirebaseUser" },
              { target: "onlyForPremium" },
            ],
          },
        },
        disabled: {
          type: "final",
        },
        onlyForPremium: {
          initial: "loading",
          states: {
            loading: {
              invoke: {
                src: "loadShoppingListFromLocalStorage",
                onDone: {
                  actions: ["onLocalStorageSnapshot"],
                  target: "merging",
                },
                onError: {
                  target: "ready",
                },
              },
            },
            merging: {
              invoke: {
                src: "loadOneRecipeById2",
                onError: {},
                onDone: [
                  {
                    actions: ["persistMergedShoppingList", "syncIngredients", "updateNotification"],
                    target: "ready",
                  },
                ],
              },
            },
            ready: {
              on: {
                ADD_EXTRA_ITEM: {
                  actions: ["addExtraItem", "writeToLocalStorage", "updateNotification"],
                },
                LIST_DELETE_CUSTOM_INGREDIENT: {
                  actions: ["deleteCustomIngredient", "writeToLocalStorage", "updateNotification"],
                },
              },
            },
          },
        },
        readyWithPremium: {
          initial: "loading",
          invoke: [
            {
              id: "firebase",
              src: "subscribeToShoppingList",
            },
            {
              id: "recipes",
              src: "recipesSubscription",
            },
          ],
          states: {
            loading: { entry: ["viewShoppingListAnalytics"] },
            groupByDepartments: {},
            groupByRecipes: {},
            merging: {
              invoke: {
                src: "loadMergedShoppingList",
                onError: {},
                onDone: [
                  {
                    actions: ["persistMergedShoppingList", "syncIngredients", "updateNotification"],
                    target: "goBack",
                  },
                ],
              },
            },
            goBack: {
              always: [
                {
                  target: "groupByDepartments",
                  cond: "isGroupByDepartments",
                },
                {
                  target: "groupByRecipes",
                },
              ],
            },
            deleteList: {
              on: {
                DELETE_SHOPPING_LIST_NO: {
                  target: "goBack",
                },
                DELETE_SHOPPING_LIST_YES: {
                  actions: ["deleteList", "writeToFirebase", "updateNotification"],
                  target: "goBack",
                },
              },
            },
            deleteRecipe: {
              on: {
                DELETE: {
                  actions: [
                    "setRelatedItemsAsNotCompleted",
                    "deleteRecipe",
                    "writeToFirebase",
                    "updateNotification",
                    "clearTmpDeleteRecipeEventInfo",
                  ],
                  target: "merging",
                },
                DELETE_RECIPE_NO: {
                  target: "goBack",
                  actions: ["clearTmpDeleteRecipeEventInfo"],
                },
                DELETE_RECIPE_YES: {
                  actions: ["sendDeleteRecipeEvent"],
                },
              },
            },
          },
          on: {
            FIREBASE_SNAPSHOT: {
              actions: ["onFirebaseSnapshot", "refreshRecipes"],
              target: ".merging",
            },
            RECIPES_LOADED: {
              actions: ["removeRecipesWithErrors", "storeRecipes"],
            },
            RECIPE_DELETE_RECIPE: {
              actions: ["saveTmpRecipeDeleteInfo"],
              target: ".deleteRecipe",
            },
            ADD_EXTRA_ITEM: {
              actions: [
                "addExtraItem",
                "writeToFirebase",
                "updateNotification",
                "addExtraItemToShoppingListOnAttemptAnalytics",
              ],
            },
            LIST_SET_CUSTOM_INGREDIENT_AS_COMPLETE: {
              actions: [
                "setCustomIngredientAsComplete",
                "writeToFirebase",
                "updateNotification",
                "completeItemOnAttemptAnalytics",
              ],
            },
            LIST_SET_CUSTOM_INGREDIENT_AS_INCOMPLETE: {
              actions: ["setCustomIngredientAsIncomplete", "writeToFirebase", "updateNotification"],
            },
            LIST_DELETE_CUSTOM_INGREDIENT: {
              actions: ["deleteCustomIngredient", "writeToFirebase", "updateNotification"],
            },
            LIST_SET_INGREDIENT_AS_COMPLETE: {
              actions: [
                "setIngredientAsComplete",
                "writeToFirebase",
                "updateNotification",
                "completeItemOnAttemptAnalytics",
              ],
            },
            LIST_SET_INGREDIENT_AS_INCOMPLETE: {
              actions: ["setIngredientAsIncomplete", "writeToFirebase", "updateNotification"],
            },
            RECIPE_INCREASE_SERVING: {
              actions: [
                "increaseServings",
                "setRelatedItemsAsNotCompleted",
                "writeToFirebase",
                "updateNotification",
              ],
            },
            RECIPE_DECREASE_SERVING: {
              actions: [
                "decreaseServings",
                "setRelatedItemsAsNotCompleted",
                "writeToFirebase",
                "updateNotification",
              ],
            },
            SET_GROUPED_BY_RECIPES: {
              target: ".groupByRecipes",
              actions: ["setGroupByRecipes", "setPreferencesInStorage"],
            },
            SET_GROUPED_BY_DEPARTMENTS: {
              target: ".groupByDepartments",
              actions: ["setGroupByDepartments", "setPreferencesInStorage"],
            },
            PRINT: {
              actions: ["print", "printShoppingListAnalytics"],
            },
            SHARE: {
              actions: ["share"],
            },
            DELETE_SHOPPING_LIST: {
              target: ".deleteList",
            },
          },
        },
      },
    },
    {
      services: {
        subscribeToShoppingList: subscribeToShoppingList(options.db),
        loadOneRecipeById2: loadOneRecipeById2(options.client),
        recipesSubscription: recipesSubscription(options.client),
        loadMergedShoppingList: loadMergedShoppingList(options.client),
        loadShoppingListFromLocalStorage,
        firebaseFetchAndActivate,
      },
      actions: {
        addExtraItem,
        clearTmpDeleteRecipeEventInfo,
        decreaseServings,
        deleteCustomIngredient,
        deleteList,
        deleteRecipe,
        increaseServings,
        onFirebaseSnapshot,
        onLocalStorageSnapshot,
        persistMergedShoppingList,
        print,
        refreshRecipes,
        saveTmpRecipeDeleteInfo,
        sendDeleteRecipeEvent,
        setIsPremium,
        setIngredientUnit,
        setCustomIngredientAsComplete,
        setCustomIngredientAsIncomplete,
        setGroupByDepartments,
        setGroupByRecipes,
        setIngredientAsComplete,
        setIngredientAsIncomplete,
        setPreferencesInStorage,
        setRelatedItemsAsNotCompleted,
        setUser,
        share,
        storeRecipes,
        syncIngredients,
        updateNotification,
        writeToFirebase,
        writeToLocalStorage,
        removeRecipesWithErrors: assign<Context, ShoppingListEvent>({
          data: (ctx, event) => {
            const data = (event as RecipesLoadedEvent).data;
            ctx.data.list.items = ctx.data.list.items.filter(
              (x) => data.find((recipe) => recipe?.id === x.id) !== undefined
            );
            return ctx.data;
          },
        }),
        viewShoppingListAnalytics: (ctx) =>
          sendEvent({
            eventName: "shopping_list",
            action: "view_shopping_list",
            payload: {
              userId: ctx.userId ? sha256(ctx.userId) : "",
              isPremium: ctx.isPremium,
            },
          }),

        addExtraItemToShoppingListOnAttemptAnalytics: (ctx) =>
          sendEvent({
            eventName: "shopping_list",
            action: "add_extra_item_to_shopping_list_attempt",
            payload: {
              userId: ctx.userId ? sha256(ctx.userId) : "",
              isPremium: ctx.isPremium,
            },
          }),
        completeItemOnAttemptAnalytics: (ctx) =>
          sendEvent({
            eventName: "shopping_list",
            action: "complete_item_in_shopping_list_attempt",
            payload: {
              userId: ctx.userId ? sha256(ctx.userId) : "",
              isPremium: ctx.isPremium,
            },
          }),
        printShoppingListAnalytics: (ctx) =>
          sendEvent({
            eventName: "shopping_list",
            action: "print_shopping_list",
            payload: {
              userId: ctx.userId ? sha256(ctx.userId) : "",
              isPremium: ctx.isPremium,
            },
          }),
      },
      guards: {
        hasDocument: (_, event) => (event as DoneInvokeEvent<FirebaseShoppingListResult>).data !== null,
        isGroupByDepartments: (ctx) => ctx.groupBy === GroupBy.DEPARTMENTS,
        isUserNotLoading: (_, event) => (event as UserStateChangeEvent).isUserLoading === false,
        isPremiumAndFirebaseUser: (ctx) => ctx.isPremium && typeof ctx.firebaseUserId !== "undefined",
        featureIsDisabled: (ctx) => !getValue(ctx.firebaseRemoteConfig, WEB_FEATURE_SHOPPING_LIST).asBoolean(),
      },
    }
  );
};
