import { Locale } from "../types/Locale";

const kilosToPounds = (value: number) => Math.round(value * 2.2046 * 10) / 10;

export const poundsToKilos = (value: number) => Math.round((value / 2.2046) * 10) / 10;

export const getShortForUnit = (unit: MeasurementUnit) => (unit === MeasurementUnit.IMPERIAL ? "lb" : "kg");

export const changeUnit = (unit: MeasurementUnit) =>
  unit === MeasurementUnit.METRIC ? MeasurementUnit.IMPERIAL : MeasurementUnit.METRIC;

export const initializeUnit = (unit: MeasurementUnit) => (value: number) =>
  unit === MeasurementUnit.METRIC ? Math.round(value * 10) / 10 : kilosToPounds(value);

export enum MeasurementUnit {
  METRIC = "metric",
  IMPERIAL = "imperial",
}

export enum IngredientUnit {
  METRIC = "metric",
  IMPERIAL = "us",
  SWEDISH = "sv",
}

export const ftToCm = 30.48;

export const inToCm = 2.54;

export const fromLanguageAndUnitToIngredientUnit = (lang: Locale, measurementUnit: MeasurementUnit) => {
  if (lang === Locale.SV) {
    return IngredientUnit.SWEDISH;
  } else if (measurementUnit === MeasurementUnit.IMPERIAL) {
    return IngredientUnit.IMPERIAL;
  } else {
    return IngredientUnit.METRIC;
  }
};
