import { useCallback, useContext, useEffect, useMemo } from "react";
import { pageContext } from "../../components/PageProvider/PageProvider";
import { MeasurementSystem } from "../../types/graphql-global-types";
import { Locale } from "../../types/Locale";
import { UpdateUser_updateUser } from "../../types/UpdateUser";
import { useUserPreferences } from "../../ecosystems/user/useUserPreferences";
import { fromLanguageAndUnitToIngredientUnit, MeasurementUnit } from "../../utils/units";
import { useStateWithLocalStorage } from "../useStateWithLocalStorage/useStateWithLocalStorage";

const measurementUnitToMeasurementSystem = (unit: MeasurementUnit) =>
  unit === MeasurementUnit.METRIC ? MeasurementSystem.METRIC : MeasurementSystem.IMPERIAL;

const measurementSystemToMeasurementUnit = (system: MeasurementSystem) =>
  system === MeasurementSystem.METRIC ? MeasurementUnit.METRIC : MeasurementUnit.IMPERIAL;

const defaultUnitByLanguage = (locale: Locale) =>
  locale === Locale.SV || Locale.ES ? MeasurementUnit.METRIC : MeasurementUnit.IMPERIAL;

const unitToPreferencesWithMeasurementSystem = (prefPref: UpdateUser_updateUser, value: MeasurementUnit) => ({
  ...prefPref,
  measurementSystem: measurementUnitToMeasurementSystem(value),
});

export function useMeasurementUnit() {
  const { locale } = useContext(pageContext);

  // local - default
  const [measurementUnit, setMeasurementUnit] = useStateWithLocalStorage(
    "dd/preferences/unit",
    defaultUnitByLanguage(locale)
  );

  // remote
  const { preferences, loading, updateUserPreferences } = useUserPreferences();

  const update = useCallback(
    (value: MeasurementUnit) => {
      // update local state
      setMeasurementUnit(value);

      // update remote state
      if (preferences) {
        updateUserPreferences(unitToPreferencesWithMeasurementSystem(preferences, value));
      }
    },
    [preferences, setMeasurementUnit, updateUserPreferences]
  );

  useEffect(() => {
    // in case the user is logged in and it have
    // preferences, we update the local state with
    // the one from the api
    if (preferences && preferences?.measurementSystem) {
      setMeasurementUnit(measurementSystemToMeasurementUnit(preferences.measurementSystem));
    }
  }, [preferences, preferences?.measurementSystem, setMeasurementUnit]);

  const isImperial = useMemo(() => measurementUnit === MeasurementUnit.IMPERIAL, [measurementUnit]);

  const ingredientUnit = useMemo(
    () => fromLanguageAndUnitToIngredientUnit(locale, measurementUnit),
    [locale, measurementUnit]
  );

  return { loading, measurementUnit, setMeasurementUnit: update, ingredientUnit, isImperial };
}
