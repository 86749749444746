import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { SignUpPage, SignUpStep } from "../../pages";
import { useNavigate } from "../../components/Link/Link";
import { useTranslations } from "./translations";

export function ShoppingListMembershipBanner() {
  const tt = useTranslations();
  const navigate = useNavigate();
  const handleNavigateToNewMemberPage = () =>
    navigate({
      to: SignUpPage,
      params: { step: SignUpStep.ACCOUNT, content: "recipe" },
    });
  return (
    <section className="my-6">
      <div className="flex items-center">
        <div className="flex-grow border-b border-light-grey"></div>

        <div className="px-3 uppercase text-grey tracking-widest" style={{ fontSize: "0.625rem" }}>
          {tt.ddPlusMembership}
        </div>
        <div className="flex-grow border-b border-light-grey"></div>
      </div>
      <div className="relative flex flex-col bg-sand px-9 py-6 rounded-3xl mt-3 overflow-hidden">
        <h1
          className="leading-8 text-2xl md:text-3xl font-medium tracking-tight md:w-2/3 m-0 mt-5 z-10"
          dangerouslySetInnerHTML={{ __html: tt.memberExclusiveBenefit }}
        ></h1>
        <p className="md:w-4/6 lg:w-5/6 text-base m-0 mt-3 mb-8 z-10">{tt.signUpFor30DayTrial}</p>
        <div className="z-10 mb-32 md:mb-0">
          <button
            className="text-sm bg-green text-white rounded-full px-7 py-2 font-medium hover:opacity-80"
            onClick={handleNavigateToNewMemberPage}
          >
            {tt.freeTrial}
          </button>
        </div>
        <div className="-bottom-8 -right-4 absolute md:bottom-0 md:right-0">
          <PreviewImage />
        </div>
      </div>
      <div className="flex items-center mt-4">
        <div className="flex-grow border-b border-light-grey"></div>
      </div>
    </section>
  );
}

const PreviewImage = () => {
  const preview = useStaticQuery(graphql`
    query {
      file(name: { eq: "shopping-list-preview" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 295, height: 274)
        }
      }
    }
  `);

  return (
    <GatsbyImage
      className="align-bottom"
      imgStyle={{ objectPosition: "bottom" }}
      image={preview.file?.childImageSharp?.gatsbyImageData}
      alt=""
    />
  );
};
