import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { DocumentData, DocumentReference } from "firebase/firestore";
import { RemoteConfig } from "firebase/remote-config";
import { DoneInvokeEvent } from "xstate";
import { Locale } from "../../types/Locale";
import { IngredientUnit } from "../../utils/units";

export const WEB_FEATURE_SHOPPING_LIST = "WEB_FEATURE_SHOPPING_LIST" as const;
export const LS_RECIPES_SHOPPING_LIST_PREFERENCES_KEY = "dd/recipes-shopping-list-pref" as const;

type FirebaseIngredientId = string;
type FirebaseRecipeId = string;
type FirebaseRecipeType = "recipe";

export type FirebaseIngredient = {
  id: FirebaseIngredientId;
  completed: boolean;
};

export type FirebaseRecipe = {
  id: FirebaseRecipeId;
  uuid: string;
  type: FirebaseRecipeType;
  servings: number;
};

export type FirebaseCustomIngredient = {
  title: string;
  completed: boolean;
};

export type FirebaseShoppingListPayload = {
  version: string;
  list: {
    ingredients: FirebaseIngredient[];
    customIngredients: FirebaseCustomIngredient[];
    items: FirebaseRecipe[];
  };
};

export type FirebaseShoppingListResult = FirebaseShoppingListPayload;

export type RawItem = {
  //TODO: update name
  completed: boolean; //TODO: legacy
  hidden: boolean; //TODO: legacy
  ingredient: {
    id: FirebaseIngredientId;
    titles: {
      shoppingList: string;
      singular: string;
      plural: string;
    };
  };
  shoppingSection: string;
  values: {
    sv: ServingItem[];
    us: ServingItem[];
    metric: ServingItem[];
  };
};

export type RecipeShoppingList = RawItem[];

export type RawRecipe = {
  //TODO: name: GraphQL recipe result?
  id: string;
  slug: string;
  title: string;
  shoppingList: RecipeShoppingList;
  images: {
    hz: string;
  };
};

type ServingItem = {
  servingSize: number;
  unit: string;
  value: string;
  dualValue: {
    unit: string;
    value: string;
  };
};

export type ShoppingListMergedIngredient = {
  ingredient: {
    id: FirebaseIngredientId;
    shoppingSection: string;
    titles: {
      singular: string;
      plural: string;
    };
  };
  values: {
    us: {
      value: string;
      unit: string;
    };
    sv: {
      value: string;
      unit: string;
    };
    metric: {
      value: string;
      unit: string;
    };
  };
};

export interface Context {
  language: Locale;
  client: ApolloClient<NormalizedCacheObject>;
  firebaseRemoteConfig: RemoteConfig;

  userId?: string;
  firebaseUserId?: string;
  isPremium: boolean;

  ingredientUnit: IngredientUnit;
  groupBy: GroupBy;

  data: FirebaseShoppingListPayload;
  recipes?: RawRecipe[];
  mergedShoppingList?: ShoppingListMergedIngredient[];

  tmpDeleteRecipeEventInfo?: {
    uuid: string;
    recipe: RawRecipe;
  };

  doc?: DocumentReference<DocumentData>;
}

type FirebaseConnectEvent = {
  type: "FIREBASE_CONNECT";
  doc: DocumentReference<DocumentData>;
};

export type FirebaseSnapshotEvent = {
  type: "FIREBASE_SNAPSHOT";
  data: FirebaseShoppingListPayload;
};

export type FirebaseWriteToEvent = {
  type: "WRITE_TO_FIREBASE";
  data: FirebaseShoppingListPayload;
};

export type RecipeRefreshIntentEvent = {
  type: "RECIPE_REFRESH_INTENT";
  data: FirebaseShoppingListPayload;
  recipes: RawRecipe[];
};

export type UserStateChangeEvent = {
  type: "USER_STATE_CHANGE";
  isUserLoading: boolean;
  isPremium: boolean;
  userId?: string;
  firebaseUserId?: string;
  ingredientUnit: IngredientUnit;
};

export type AddExtraItemEvent = {
  type: "ADD_EXTRA_ITEM";
  title: string;
};

type SetGroupedByDepartmentsEvent = {
  type: "SET_GROUPED_BY_DEPARTMENTS";
};

type SetGroupedByRecipesEvent = {
  type: "SET_GROUPED_BY_RECIPES";
};

type ShareEvent = {
  type: "SHARE";
};

type PrintEvent = {
  type: "PRINT";
};

type DeleteShoppingListEvent = {
  type: "DELETE_SHOPPING_LIST";
};

type DeleteShoppingListYesEvent = {
  type: "DELETE_SHOPPING_LIST_YES";
};

type DeleteShoppingListNoEvent = {
  type: "DELETE_SHOPPING_LIST_NO";
};

export type RecipesLoadedEvent = {
  type: "RECIPES_LOADED";
  data: (RawRecipe | null)[];
};

export type RecipeDeleteRecipeEvent = {
  type: "RECIPE_DELETE_RECIPE";
  recipe: RawRecipe;
  uuid: string;
};

export type RecipeIncreaseServingEvent = {
  type: "RECIPE_INCREASE_SERVING";
  recipe: RawRecipe;
  servings: number;
  uuid: string;
};
type RecipeDecreaseServingEvent = {
  type: "RECIPE_DECREASE_SERVING";
  recipe: RawRecipe;
  servings: number;
  uuid: string;
};

export type ListDeleteCustomIngredientEvent = {
  type: "LIST_DELETE_CUSTOM_INGREDIENT";
  ingredient: FirebaseCustomIngredient;
};

export type ListSetCustomIngredientAsCompleteEvent = {
  type: "LIST_SET_CUSTOM_INGREDIENT_AS_COMPLETE";
  ingredient: FirebaseCustomIngredient;
};

export type ListSetCustomIngredientAsIncompleteEvent = {
  type: "LIST_SET_CUSTOM_INGREDIENT_AS_INCOMPLETE";
  ingredient: FirebaseCustomIngredient;
};

export type ListSetIngredientAsCompleteEvent = {
  type: "LIST_SET_INGREDIENT_AS_COMPLETE";
  id: FirebaseIngredientId;
};

export type ListSetIngredientAsIncompleteEvent = {
  type: "LIST_SET_INGREDIENT_AS_INCOMPLETE";
  id: FirebaseIngredientId;
};

export type ShoppingListEvent =
  | AddExtraItemEvent
  | DoneInvokeEvent<ShoppingListMergedIngredient[]>
  | DeleteShoppingListEvent
  | DeleteShoppingListNoEvent
  | DeleteShoppingListYesEvent
  | ListDeleteCustomIngredientEvent
  | ListSetCustomIngredientAsCompleteEvent
  | ListSetCustomIngredientAsIncompleteEvent
  | ListSetIngredientAsCompleteEvent
  | ListSetIngredientAsIncompleteEvent
  | PrintEvent
  | RecipeDecreaseServingEvent
  | RecipeDeleteRecipeEvent
  | RecipeIncreaseServingEvent
  | ShareEvent
  | SetGroupedByDepartmentsEvent
  | SetGroupedByRecipesEvent
  | UserStateChangeEvent
  | FirebaseConnectEvent
  | FirebaseSnapshotEvent
  | FirebaseWriteToEvent
  | RecipesLoadedEvent
  | RecipeRefreshIntentEvent;

export enum GroupBy {
  RECIPES = 0,
  DEPARTMENTS = 1,
}

export type Preferences = {
  groupBy: GroupBy;
};
