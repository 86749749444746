import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { collection, doc, Firestore, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { fetchAndActivate } from "firebase/remote-config";
import { EventObject, Receiver, Sender } from "xstate";
import {
  Context,
  FirebaseShoppingListPayload,
  FirebaseSnapshotEvent,
  FirebaseWriteToEvent,
  RawRecipe,
  RecipeRefreshIntentEvent,
  ShoppingListEvent,
  ShoppingListMergedIngredient,
} from "./types";
import { emptyServingItem, emptyShoppingList } from "./utils";

export const subscribeToShoppingList =
  (db: Firestore) =>
  (ctx: Context) =>
  (callback: Sender<ShoppingListEvent>, onReceive: Receiver<EventObject>) => {
    // we subscribe to a Firebase document to update and listen for changes
    const collectionRef = collection(
      db,
      "shopping_list",
      encodeUserIdForFirestore(ctx.firebaseUserId!),
      ctx.language
    );

    const docRef = doc(collectionRef, "list");

    onReceive((event) => {
      if ((event as ShoppingListEvent).type === "WRITE_TO_FIREBASE") {
        // if we receive an event from the machine
        // we send and update to Firebase
        const data = (event as FirebaseWriteToEvent).data;

        updateDoc(docRef, { json: JSON.stringify(data) }).catch(() => {
          // the update command could fail if the document doesn't exist
          // in that case, let's create one
          setDoc(docRef, { json: JSON.stringify(data) });
        });
      }
    });

    const unsubscribe = onSnapshot(
      docRef,
      (doc) => {
        // Firebase notify us when a change is made to the document,
        // this is specially useful to get in sync multiple clients
        // if we get data, first we send back an event to the machine to update
        // the data, last we load the merged list and the recipes if there is a new one
        if (doc.exists()) {
          const data: FirebaseShoppingListPayload = JSON.parse(doc.data()!.json);

          callback({
            type: "FIREBASE_SNAPSHOT",
            data,
          });
        } else {
          // on first loading, if the document doesn't exist
          // let's just return the initial value (an empty shopping list)
          callback({
            type: "FIREBASE_SNAPSHOT",
            data: ctx.data,
          });
        }
      },
      (error) => {
        console.log({ error });
      }
    );

    return () => unsubscribe();
  };

export const recipesSubscription =
  (client: ApolloClient<NormalizedCacheObject>) =>
  (
    _: Context // eslint-disable-line @typescript-eslint/no-unused-vars
  ) =>
  (callback: Sender<ShoppingListEvent>, onReceive: Receiver<EventObject>) => {
    onReceive((event) => {
      if ((event as ShoppingListEvent).type === "RECIPE_REFRESH_INTENT") {
        // when Firebase notify us about a change in the document, we need
        // to figure out if the change made is a new recipe added.
        // This could happen due to: A recipe added in the ios/android app
        // a recipe added from the Recipe's page, or from another client.
        const data = (event as RecipeRefreshIntentEvent).data;
        const recipeIdsLoaded = (event as RecipeRefreshIntentEvent).recipes?.map((x) => x.id) ?? [];
        const onSnapshotRecipeIds = data.list.items.map((x) => x.id);

        const hasMissingRecipe = onSnapshotRecipeIds.some(
          (id) => recipeIdsLoaded.find((x) => x === id) === undefined
        );

        // if we don't have information about a recipe, the entire recipe list is refreshed
        if (hasMissingRecipe) {
          const recipePromises = onSnapshotRecipeIds.map(loadOneRecipeById(client));
          Promise.all(recipePromises).then((data) => {
            callback({ type: "RECIPES_LOADED", data });
          });
        }
      }
    });
  };

export const loadOneRecipeById2 =
  (client: ApolloClient<NormalizedCacheObject>) => (ctx: Context, e: ShoppingListEvent) => () => {
    const items = (e as FirebaseSnapshotEvent).data.list.items;
    if (items.length === 0) {
      return Promise.resolve([]);
    }

    const { id, servings } = items[0];

    return loadOneRecipeById(client)(id).then((data) => {
      return data
        ? data.shoppingList.map((item) => {
            return {
              ingredient: {
                id: item.ingredient.id,
                shoppingSection: item.shoppingSection,
                titles: {
                  singular: item.ingredient.titles.singular,
                  plural: item.ingredient.titles.plural,
                },
              },
              values: {
                us: item.values.us.find((x) => x.servingSize === servings) ?? emptyServingItem(),
                sv: item.values.sv.find((x) => x.servingSize === servings) ?? emptyServingItem(),
                metric: item.values.metric.find((x) => x.servingSize === servings) ?? emptyServingItem(),
              },
            };
          })
        : [];
    });
  };

const loadOneRecipeById = (client: ApolloClient<NormalizedCacheObject>) => (recipeId: string) =>
  client
    .query({
      query: gql(`query GetRecipe($id: ID!) {
            recipe(id: $id) {
              id
              slug
              title
              images {
                hz
                brightness
              }
              shoppingList {
                ingredient {
                  id
                  titles {
                    shoppingList
                    singular
                    plural
                  }
                }
                shoppingSection
                values {
                  sv {
                    servingSize
                    unit
                    value
                    dualValue {
                      unit
                      value
                    }
                  }
                  us {
                    servingSize
                    unit
                    value
                    dualValue {
                      unit
                      value
                    }
                  }
                  metric {
                    servingSize
                    unit
                    value
                    dualValue {
                      unit
                      value
                    }
                  }
                }
              }
            }
          }
          `),
      variables: {
        id: recipeId,
      },
    })
    .then(({ data }: { data: { recipe: RawRecipe } }) => data.recipe)
    .catch(() => null);

const removePrefix = (prefix: string) => (str: string) =>
  str.indexOf(prefix) === 0 ? str.substring(prefix.length) : str;

const encodeUserIdForFirestore: (str: string) => string = removePrefix("users/");

export const loadMergedShoppingList = (client: ApolloClient<NormalizedCacheObject>) => (ctx: Context) => {
  return new Promise<ShoppingListMergedIngredient[]>((resolve, reject) => {
    const toMerge = ctx.data.list.items.map((x) => ({ id: x.id, servings: x.servings }));
    if (toMerge.length === 0) {
      return resolve([]);
    }

    const query = `query {
      mergeIngredients(input: {
        recipes: [
          ${toMerge.map((x) => `{ id: "${x.id}", servings: ${x.servings} }`).join(",")}
        ]
      }){
        ingredients {
          ingredient {
            id,
            titles {
              singular,
              plural,
            },
            shoppingSection,
          }
          values {
            us {
              value,
              unit,
            },
            sv {
              value,
              unit
            },
            metric {
              value,
              unit
            }
          }
        }
      }}`;

    client
      .query({
        // TODO: Create a multiple recipe query
        query: gql(query),
      })
      .then((data) => data.data.mergeIngredients.ingredients)
      .catch(reject)
      .then(resolve);
  });
};

export const firebaseFetchAndActivate = (ctx: Context) => {
  ctx.firebaseRemoteConfig.settings = {
    fetchTimeoutMillis: 60000,
    minimumFetchIntervalMillis: 20000,
  };
  return fetchAndActivate(ctx.firebaseRemoteConfig);
};

export const loadShoppingListFromLocalStorage = () => {
  return new Promise<FirebaseShoppingListPayload>((resolve) => {
    try {
      const maybeList = localStorage.getItem("dd/shopping-list");
      const data: FirebaseShoppingListPayload = typeof maybeList === "string" ? JSON.parse(maybeList) : null;
      if (data !== null) {
        resolve(data);
      } else {
        resolve(emptyShoppingList());
      }
    } catch (e) {
      resolve(emptyShoppingList());
    }
  });
};
