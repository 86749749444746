import React from "react";
import { InterpreterFrom, StateMachine, StateSchema } from "xstate";
import { IngredientUnit } from "../../../utils/units";
import { Translations } from "../translations";
import { Context, FirebaseIngredient, FirebaseRecipe, RawRecipe, ShoppingListEvent } from "../types";
import { Recipe } from "./Recipe";

type Props = {
  translations: Translations;
  recipes: RawRecipe[];
  ingredients: FirebaseIngredient[];
  items: FirebaseRecipe[];
  ingredientUnit: IngredientUnit;
  send: InterpreterFrom<StateMachine<Context, StateSchema<Context>, ShoppingListEvent>>["send"];
};

export function RecipeList({ translations, ingredientUnit, recipes, ingredients, items, send }: Props) {
  return (
    <ul className="list-none m-0 p-0 mt-11 w-full">
      {items.map((item, i) => (
        <Recipe
          translations={translations}
          key={i}
          ingredientUnit={ingredientUnit}
          recipe={recipes.find((recipe) => recipe.id === item.id)}
          uuid={item.uuid}
          servings={item.servings}
          ingredients={ingredients}
          send={send}
        />
      ))}
    </ul>
  );
}
