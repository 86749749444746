import * as React from "react";

type Props = {
  title: string;
  yesLabel: string;
  noLabel: string;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
};

export function ConfirmationModal({ title, yesLabel, noLabel, onConfirm, onCancel }: Props) {
  return (
    <div className="fixed z-9999 bottom-0 left-0 right-0 top-0 bg-lighter-grey bg-opacity-95 flex flex-col justify-end md:justify-center p-4 transition duration-300">
      <ul className="rounded-md list-none m-0 p-0 w-full md:max-w-xl md:mx-auto bg-white px-6 md:px-12 py-6 flex flex-col">
        <li>
          <h1 className="text-black text-xl font-medium m-0 mb-2 p-0">{title}</h1>
        </li>
        <li>
          <button
            className="text-black hover:opacity-50 transition w-full flex justify-start bg-white py-4"
            onClick={onConfirm}
          >
            {yesLabel}
          </button>
        </li>
        <li className="border-t border-light-grey">
          <button
            className="text-black hover:opacity-50 transition w-full flex justify-start bg-white py-4"
            onClick={onCancel}
          >
            {noLabel}
          </button>
        </li>
      </ul>
    </div>
  );
}
