import { default as React } from "react";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";
import { InterpreterFrom, StateMachine, StateSchema } from "xstate";
import { IngredientUnit } from "../../../utils/units";
import { Translations } from "../translations";
import {
  Context,
  FirebaseCustomIngredient as FCI,
  FirebaseShoppingListPayload as FSLP,
  ShoppingListEvent,
  ShoppingListMergedIngredient as SLMI,
} from "../types";
import { hasIngredients } from "../utils";
import { ListRow } from "./ListRow";

type Props = {
  translations: Translations;
  mergedShoppingList: SLMI[];
  data: FSLP;
  ingredientUnit: IngredientUnit;
  send: InterpreterFrom<StateMachine<Context, StateSchema<Context>, ShoppingListEvent>>["send"];
};

export function List({ translations, ingredientUnit, mergedShoppingList, data, send }: Props) {
  const handleCustomComplete = (ingredient: FCI) => () =>
    send({ type: "LIST_SET_CUSTOM_INGREDIENT_AS_COMPLETE", ingredient });
  const handleCustomIncomplete = (ingredient: FCI) => () =>
    send({ type: "LIST_SET_CUSTOM_INGREDIENT_AS_INCOMPLETE", ingredient });
  const handleCustomHideOne = (ingredient: FCI) => () =>
    send({ type: "LIST_DELETE_CUSTOM_INGREDIENT", ingredient });
  const handleComplete = (id: string) => () => send({ type: "LIST_SET_INGREDIENT_AS_COMPLETE", id });
  const handleIncomplete = (id: string) => () => send({ type: "LIST_SET_INGREDIENT_AS_INCOMPLETE", id });

  const { ingredients, items, customIngredients } = data.list;

  const customNonCompleted = customIngredients.filter((x) => !x.completed);
  const customCompleted = customIngredients.filter((x) => x.completed);

  const mergedNonCompleted = mergedShoppingList.filter(
    (x) => !ingredients.find((y) => y.id === x.ingredient.id)?.completed
  );

  const mergedCompleted = mergedShoppingList
    .filter((x) => ingredients.find((y) => y.id === x.ingredient.id)?.completed)
    .sort((a, b) =>
      a.ingredient.titles.singular.toLowerCase() > b.ingredient.titles.singular.toLowerCase() ? 1 : -1
    );

  const allCompleted = [...customCompleted, ...mergedCompleted];

  // ingredients grouped by department/section
  const bySection = Object.entries(
    mergedNonCompleted?.reduce((acc: Record<string, SLMI[]>, x) => {
      if (acc[x.ingredient.shoppingSection] === undefined) {
        acc[x.ingredient.shoppingSection] = [];
      }
      acc[x.ingredient.shoppingSection] = [...acc[x.ingredient.shoppingSection], x];
      acc[x.ingredient.shoppingSection] = acc[x.ingredient.shoppingSection].sort((a, b) =>
        a.ingredient.titles.singular.toLowerCase() > b.ingredient.titles.singular.toLowerCase() ? 1 : -1
      );

      return acc;
    }, {}) ?? {}
  ).sort((a, b) => (a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1));

  const shoppingListHasIngredients = hasIngredients(
    [...mergedNonCompleted, ...mergedCompleted],
    [...customNonCompleted, ...customCompleted]
  );

  return (
    <div className="flex flex-col w-full mt-6">
      {!shoppingListHasIngredients && (
        <div>
          <h3>{translations.noIngredientsListed.title}</h3>
          <p>
            <strong>{translations.noIngredientsListed.tip}</strong>
            {translations.noIngredientsListed.toStartAddToShoppingList}
          </p>
        </div>
      )}
      {shoppingListHasIngredients && (
        <ListOfIngredients className="list-none m-0 p-0 w-full">
          {customNonCompleted.length > 0 && (
            <li key="extras">
              <h3 className="font-medium text-2xl print:text-base p-0 m-0 pl-1.5 pt-10">
                {translations.extras}
              </h3>
              <ul className="list-none m-0 p-0">
                {/* EXTRAS! only */}
                {customNonCompleted.map((ingredient: FCI) => {
                  return (
                    <ListRow
                      isCustomIngredient={true}
                      key={uuidv4()}
                      isCompleted={false}
                      ingredientUnit={ingredientUnit}
                      title={ingredient.title}
                      servings={null}
                      values={null}
                      handleChange={handleCustomComplete(ingredient)}
                      handleHideOne={handleCustomHideOne(ingredient)}
                    />
                  );
                })}
              </ul>
            </li>
          )}
          {bySection.map((item) => (
            <li key={uuidv4()}>
              {/* OTHER SECTIONS */}
              <h3 className="font-medium text-2xl print:text-base p-0 m-0 pl-1.5 pt-10 print:pt-4">
                {item[0]}
              </h3>
              <ul className="list-none m-0 p-0">
                {item[1].map((x) => {
                  const recipe = items.find((recipe) => recipe.id === x.ingredient.id);
                  const servings = recipe?.servings ?? 1;

                  return (
                    <ListRow
                      isCustomIngredient={false}
                      key={uuidv4()}
                      isCompleted={false}
                      ingredientUnit={ingredientUnit}
                      title={x.ingredient.titles.singular}
                      servings={servings}
                      values={x.values}
                      handleChange={handleComplete(x.ingredient.id)}
                    />
                  );
                })}
              </ul>
            </li>
          ))}
        </ListOfIngredients>
      )}
      {allCompleted.length > 0 && (
        <div className="my-4">
          <h3 className="font-medium text-2xl print:text-base p-0 m-0 pl-1.5 pt-10">Completed</h3>
          <ul className="list-none m-0 p-0">
            {customCompleted.map((ingredient) => {
              return (
                <ListRow
                  isCustomIngredient={true}
                  key={uuidv4()}
                  isCompleted={true}
                  ingredientUnit={ingredientUnit}
                  title={ingredient.title}
                  servings={null}
                  values={null}
                  handleChange={handleCustomIncomplete(ingredient)}
                  handleHideOne={handleCustomHideOne(ingredient)}
                />
              );
            })}
            {mergedCompleted.map((x) => {
              const recipe = items.find((recipe) => recipe.id === x.ingredient.id);
              const servings = recipe?.servings ?? 1;
              return (
                <ListRow
                  isCustomIngredient={false}
                  key={uuidv4()}
                  isCompleted={true}
                  ingredientUnit={ingredientUnit}
                  title={x.ingredient.titles.singular}
                  servings={servings}
                  values={null}
                  handleChange={handleIncomplete(x.ingredient.id)}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

const ListOfIngredients = styled.ul`
  @media print {
    column-count: 2;
    column-gap: 80px;
  }
`;
