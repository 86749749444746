import styled from "@emotion/styled";
import { default as colors } from "../../../utils/colors.json";

export const Switch = styled.div<{ enabled: boolean }>`
  pointer-events: ${(props) => (props.enabled ? "auto" : "none")};
  -webkit-tap-highlight-color: transparent;
  position: relative;
  background: #f3f3f3;
  border-radius: 30px;
  flex: 0 0 170px;
  height: 40px;
  > button {
    position: absolute;
    z-index: 1;
    top: 7px;
    left: 5px;
    width: calc(50% - 5px);
    height: 30px;
    color: #b1b1b1;
    background: transparent;
    border: 0;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s linear;
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-active="true"] {
      color: white;
      cursor: auto;
    }

    &[data-active="false"]:hover {
      color: #727171;
    }

    &:focus {
      outline: 0;
    }
  }
  > button:nth-of-type(2) {
    left: 50%;
  }
  > span {
    position: absolute;
    z-index: 0;
    background: ${colors.GREEN};
    width: calc(50% - 5px);
    height: 30px;
    top: 5px;
    bottom: 5px;
    left: 5px;
    border-radius: 40px;
    transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);

    &[data-value="0"] {
      left: 5px;
    }

    &[data-value="1"] {
      left: 50%;
    }
  }
`;
